<template>
  <b-card no-body>
    <b-card-body>
      <div class="field-heading">
        Collection Name:
        <span class="font-weight-bold color-actions-text-grey">
          {{ order.collectionName }}
        </span>
      </div>
      <div class="field-heading">
        <span>Notes: </span>
        <b-button
          v-if="isEditNotes"
          class="ml-1"
          size="sm"
          variant="custom-primary"
          :disabled="order.notes === notes"
          @click="onUpdateNotes"
        >
          Save
        </b-button>
        <span
          v-else
          class="font-weight-bold color-actions-text-grey"
        >
          {{ notes }}
          <feather-icon
            v-if="isRetailer"
            icon="EditIcon"
            size="15"
            class="edit-icon"
            @click="isEditNotes = true"
          />
        </span>
      </div>
      <template v-if="isEditNotes">
        <div class="mb-1 d-flex">
          <b-form-textarea
            v-model="notes"
            placeholder="Enter Notes"
          />
        </div>
      </template>
      <template v-if="isBrand">
        <div class="field-heading">
          Retailer Name:
          <span class="font-weight-bold color-actions-text-grey">
            {{ order.retailerName }} {{ order.retailerBrandIdentity ? `(${order.retailerBrandIdentity})` : '' }}
          </span>
        </div>
        <div
          v-if="order.retailerFirstName"
          class="field-heading"
        >
          Buyer Name:
          <span class="font-weight-bold color-actions-text-grey">
            {{ order.retailerFirstName }}
          </span>
        </div>
      </template>
      <template v-if="isRetailer && hasBillingAddress">
        <div class="field-heading">
          Billing Address:
        </div>
        <p class="font-weight-bold color-action-text-gray">
          <span>{{ order.billingAddressForRetailer.billingContactName }},
          </span>
          <span>{{ order.billingAddressForRetailer.billingContactNumber }},
          </span>
          <span>{{ order.billingAddressForRetailer.billingAddressLine1 }},
          </span>
          <span
            v-if="order.billingAddressForRetailer.billingAddressLine2"
          >{{ order.billingAddressForRetailer.billingAddressLine2 }},
          </span>
          <span>{{ order.billingAddressForRetailer.billingCity }}, </span>
          <span>{{ order.billingAddressForRetailer.billingCountry }}</span>
          <span> - {{ order.billingAddressForRetailer.billingZipcode }}</span>
        </p>
      </template>
      <template v-if="hasShippingAddress">
        <div class="field-heading">
          Shipping Address:
        </div>
        <p class="font-weight-bold color-action-text-gray">
          <span>{{ order.shippingAddressForRetailer.shippingContactName }},
          </span>
          <span>{{ order.shippingAddressForRetailer.shippingContactNumber }},
          </span>
          <span>{{ order.shippingAddressForRetailer.shippingAddressLine1 }},
          </span>
          <span
            v-if="order.shippingAddressForRetailer.shippingAddressLine2"
          >{{ order.shippingAddressForRetailer.shippingAddressLine2 }},
          </span>
          <span>{{ order.shippingAddressForRetailer.shippingCity }}, </span>
          <span>{{ order.shippingAddressForRetailer.shippingCountry }}</span>
          <span> - {{ order.shippingAddressForRetailer.shippingZipcode }}</span>
        </p>
      </template>
    </b-card-body>
  </b-card>
</template>

<script>
import {
 BButton, BCard, BCardBody, BFormTextarea,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import store from '@/store'
import { UPDATE_SHIPMENT } from '@/store/modules/shipment.module'
import { UPDATE_ORDER } from '@/store/modules/order.module'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import UserRoleMixin from '../UserRoleMixin.vue'

export default {
  name: 'OrderAddresses',
  components: {
    BCard,
    BCardBody,
    BFormTextarea,
    BButton,
    FeatherIcon,
  },
  mixins: [UserRoleMixin],
  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      isEditNotes: false,
      notes: '',
    }
  },
  computed: {
    hasBillingAddress() {
      return (
        this.order?.billingAddressForRetailer?.billingAddressLine1
        && this.order?.billingAddressForRetailer?.billingContactName
      )
    },
    hasShippingAddress() {
      return (
        this.order?.shippingAddressForRetailer?.shippingAddressLine1
        && this.order?.shippingAddressForRetailer?.shippingContactName
      )
    },
  },
  created() {
    this.notes = this.order?.notes || ''
  },
  methods: {
    onUpdateNotes() {
      const payload = {
        notes: this.notes,
      }
      const updateApi = this.order.shipmentId
        ? store.dispatch(UPDATE_SHIPMENT, {
            shipmentId: this.order.shipmentId,
            payload,
          })
        : store.dispatch(UPDATE_ORDER, { orderId: this.order._id, payload })
      updateApi
        .then(() => {
          this.isEditNotes = false
          apiToastSuccess('Notes updated successfully')
        })
        .catch(error => {
          apiToastError(error)
        })
    },
  },
}
</script>
