<template>
  <b-card no-body>
    <b-card-body>
      <div class="field-heading color-dark-blue">
        {{ order.shipmentId ? 'Shipment' : 'Order' }} Summary
        <span class="ml-2 float-right">
          <b-button
            v-if="changeDate"
            variant="success"
            size="sm"
            class="mr-1"
            @click="onUpdateAvailabilityDate"
          >
            <feather-icon
              icon="CheckIcon"
              size="15"
            />
          </b-button>
          <b-button
            v-if="changeDate"
            variant="danger"
            size="sm"
            @click="resetDate"
          >
            <feather-icon
              icon="XIcon"
              size="15"
            />
          </b-button>
        </span>
      </div>

      <div class="field-heading">
        Total Quantity:
        <span class="field-value">{{
          formatNumber(order.totalQuantity || 0)
        }}</span>
      </div>
      <div
        v-if="shouldDisplayCreditPoints"
        class="field-heading"
      >
        Applied credit points:
        <span class="field-value">{{
          formatCurrency(order.discountInCredit, currency)
        }}</span>
      </div>
      <div class="field-heading">
        Total Cost:
        <span
          v-if="shouldDisplayCreditPoints"
          class="field-value"
        >
          {{ formatCurrency(order.totalPrice, currency) }}
        </span>
        <span
          :class="
            shouldDisplayCreditPoints &&
              'mr-5-px mt-1-px text-line-through font-small-4 font-weight-normal'
          "
          class="field-value"
        >{{ formatCurrency(order.totalPrice || 0, currency) }}</span>
      </div>
      <div class="field-heading">
        Payment Terms:
        <span class="field-value">{{ order.paymentTerms }}</span>
      </div>
      <div class="field-heading">
        <span class="text-nowrap"> Logistic Terms: </span>
        <span class="field-value">{{ order.logisticsTerms }}</span>
      </div>
      <div
        v-if="!order.shipmentId"
        class="field-heading"
      >
        Requested Delivery Window:
        <span
          v-if="!editRequestDeliveryDate"
          class="field-value"
        >
          <span v-if="order.requestedDeliveryDate">
            {{ requestedDeliveryDate }}
          </span>
          <!-- edit icon for Retailer -->
          <feather-icon
            v-if="isRetailer && canEditDate"
            icon="EditIcon"
            size="15"
            class="edit-icon"
            @click="editRequestDeliveryDate = !editRequestDeliveryDate"
          />
        </span>
        <span
          v-if="isRetailer && editRequestDeliveryDate"
          class="float-right exp-av-date"
        >
          <br class="d-block d-sm-none">
          <flat-pickr
            ref="deliveryDate"
            v-model="requestDeliveryDate"
            class="form-control exp-calender"
            :config="VUE_FLATPICKR_MONTH_CONFIG"
            :placeholder="requestDeliveryDate || KP_MONTH_FORMAT.PLACEHOLDER"
          />
          <feather-icon
            icon="CalendarIcon"
            size="20"
            class="float-right calender-icon"
            @click="togglereqDeliveryDate"
          />
        </span>
      </div>
      <div
        v-if="!order.shipmentId"
        class="field-heading"
      >
        Estimated Delivery Window:
        <span
          v-if="!onDisplayCalender"
          class="field-value"
        >{{ order.expectedAvailabilityDate }}
          <!-- edit icon for brand -->
          <feather-icon
            v-if="isBrand && canEditDate"
            icon="EditIcon"
            size="15"
            class="edit-icon"
            @click="onEdit"
          />
        </span>
        <span
          v-if="isBrand && onDisplayCalender"
          class="float-right exp-av-date"
        >
          <br class="d-block d-sm-none">
          <!-- date picker for brand -->
          <flat-pickr
            ref="expectedDate"
            v-model="order.expectedAvailabilityDate"
            class="form-control exp-calender"
            :config="VUE_FLATPICKR_MONTH_CONFIG"
            :placeholder="
              order.expectedAvailabilityDate || KP_MONTH_FORMAT.PLACEHOLDER
            "
            @on-change="onUpdateExpectedAvailabilityDate"
          />
          <feather-icon
            icon="CalendarIcon"
            size="20"
            class="float-right calender-icon"
            @click="toggleDatePicker"
          />
        </span>
      </div>
      <div
        v-if="isDiscountVisible"
        class="field-heading"
      >
        Discount:
        <span
          v-if="!isEditDiscount"
          class="field-value"
        >{{ discount }}%
          <!-- edit icon for brand -->
          <feather-icon
            v-if="isBrand"
            icon="EditIcon"
            size="15"
            class="edit-icon"
            @click="isEditDiscount = true"
          />
        </span>
        <span
          v-if="isEditDiscount"
          class="float-right exp-av-date d-flex"
        >
          <br class="d-block d-sm-none">
          <validation-provider
            #default="{ errors }"
            name="Discount"
            :rules="`between:0,100`"
            class="d-flex"
          >
            <b-form-input
              v-model="discount"
              class="w-75"
              type="number"
              size="sm"
              placeholder="Discount"
              :class="errors.length && 'danger-input'"
            />
            <b-button
              class="ml-10-px"
              size="sm"
              variant="custom-primary"
              :disabled="!!(errors.length || order.discount === discount)"
              @click="onUpdateDiscount"
            >Save</b-button>
          </validation-provider>
        </span>
      </div>
    </b-card-body>
    <!-- modal expected availability date mandatory -->
    <b-modal
      v-if="isBrand"
      id="modal-exp-av-date"
      ok-variant="success"
      ok-title="Select Date"
      cancel-title="Cancel"
      modal-class="modal-success"
      centered
      title="Estimated delivery window"
      @ok="onEdit"
    >
      <b-card-text>
        Please select estimated delivery window for this order.
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
apiToastError,
apiToastErrorV2,
apiToastSuccess,
apiToastWarning,
} from '@/@core/utils/toast'
import { formatNumber } from '@/@core/utils/utils'
import { MONTH_START_DATE, VUE_FLATPICKR_MONTH_CONFIG } from '@/constants'
import store from '@/store'
import { UPDATE_ORDER } from '@/store/modules/order.module'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import {
BButton,
BCard,
BCardBody,
BCardText,
BFormInput,
BModal,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import UserRoleMixin from '../UserRoleMixin.vue'

const {
 calculateCommercialTerms,
  convertValueToPercent,
  formatCurrency,
  formattedDate,
  getMonthAndYearFromDate,
} = utils

const {
 KP_MONTH_FORMAT,
  ORDER_STATUS,
} = c

export default {
  name: 'OrderSummary',
  components: {
    BCard,
    BCardBody,
    BButton,
    BFormInput,
    BCardText,
    BModal,
    FeatherIcon,
    flatPickr,
    ValidationProvider,
  },
  mixins: [UserRoleMixin],
  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      requestDeliveryDate: null,
      changeDate: false,
      onDisplayCalender: false,
      editRequestDeliveryDate: false,
      requestedDeliveryDate: null,
      formatNumber,
      formatCurrency,
      discount: null,
      canEditDate: true,
      isEditDiscount: false,
      KP_MONTH_FORMAT,
      VUE_FLATPICKR_MONTH_CONFIG,
    }
  },
  computed: {
    getCurrentDate() {
      return formattedDate(new Date())
    },
    currency() {
      if (this.isBrand) {
        return this.order.currencyForBrand
      }
      return this.order.currency
    },
    shouldDisplayCreditPoints() {
      return (
        this.isRetailer
        && ((this.order.discountInCredit && !this.order.isDataForCreateShipment)
          || (this.order.discountInCredit
            && this.order.isDataForCreateShipment
            && this.order.remainingCredits))
      )
    },
    getminDate() {
      const minDate = new Date()
      const minAvailbalityDate = new Date(this.order.createdAt)
      minAvailbalityDate.setDate(minAvailbalityDate.getDate() + 7)
      return minDate > minAvailbalityDate
        ? formattedDate(minDate)
        : formattedDate(minAvailbalityDate)
    },
    isDiscountVisible() {
      return !this.order.shipmentId && this.order.isSelfManagedOrder && this.isBrand
    },
  },
  watch: {
    requestDeliveryDate(newVal) {
      this.onRequestedDeliveryDateChange(newVal)
    },
  },
  created() {
    this.initRequestedDeliveryDates()
    this.discount = convertValueToPercent(this.order?.discount || 0)
    this.canEditDate = !(
      ORDER_STATUS.CANCELLED === this.order.status
      || ORDER_STATUS.REJECTED === this.order.status
    )
  },
  emits: ['load-order'],
  methods: {
    initRequestedDeliveryDates() {
      if (this.order.requestedDeliveryDate) {
        this.requestDeliveryDate = getMonthAndYearFromDate(
          this.order.requestedDeliveryDate,
        )
        this.requestedDeliveryDate = getMonthAndYearFromDate(
          this.order.requestedDeliveryDate,
        )
      }
    },
    // update expected Availability Date
    onUpdateAvailabilityDate() {
      const date = `${MONTH_START_DATE}${this.requestDeliveryDate}`
      const payload = {
        requestedDeliveryDate: date,
      }
      store
        .dispatch(UPDATE_ORDER, {
          orderId: this.order._id,
          payload,
        })
        .then(res => {
          this.changeDate = false
          this.onDisplayCalender = false
          this.editRequestDeliveryDate = false
          this.requestedDeliveryDate = this.requestDeliveryDate
          this.order.requestedDeliveryDate = this.requestDeliveryDate
          apiToastSuccess(res.data.message || 'Updated date!')
        })
        .catch(err => apiToastWarning(err))
    },
    toggleDatePicker() {
      this.$refs.expectedDate.$el._flatpickr.toggle()
    },
    togglereqDeliveryDate() {
      this.$refs.deliveryDate.$el._flatpickr.toggle()
    },
    // on click edit button
    onEdit() {
      this.onDisplayCalender = true
      setTimeout(() => {
        this.toggleDatePicker()
      }, 300)
    },
    onUpdateExpectedAvailabilityDate(date) {
      if (this.isRetailer) {
        return
      }
      const expectedAvailabilityDate = formattedDate(date[0])
      const payload = {
        expectedAvailabilityDate,
      }
      store
        .dispatch(UPDATE_ORDER, { orderId: this.order._id, payload })
        .then(() => {
          apiToastSuccess('Estimated delivery window updated successfully')
        })
        .catch(error => {
          apiToastError(error)
        })
      this.onDisplayCalender = false
    },
    onUpdateDiscount() {
      const payload = {
        discount: calculateCommercialTerms(Number(this.discount)),
      }
      store
        .dispatch(UPDATE_ORDER, { orderId: this.order._id, payload })
        .then(() => {
          this.isEditDiscount = false
          apiToastSuccess('Discount updated successfully')
          this.$emit('load-order')
        })
        .catch(error => {
          apiToastErrorV2(error)
        })
    },
    // on requested delivery date change
    onRequestedDeliveryDateChange(selectedDate = null) {
      this.changeDate = false
      if (selectedDate && selectedDate !== this.requestedDeliveryDate) {
        this.changeDate = true
      }
    },
    resetDate() {
      this.changeDate = false
      this.onDisplayCalender = false
      this.editRequestDeliveryDate = false
      this.requestDeliveryDate = this.order.requestedDeliveryDate
    },
  },
}
</script>
