<template>
  <div class="d-flex">
    <b-button
      variant="secondary-primary"
      @click="onClickOrderUploadInvoice"
    >
      Upload Invoice
    </b-button>
    <b-modal
      id="upload_order_invoice"
      modal-class="custom-modal-variant custom-model"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-footer
      @show="clearForm"
    >
      <template #modal-title>
        <div class="elements-center">
          <span class="modal-rounded-icon blue-border">
            <upload-icon />
          </span>
          <span class="color-dark-blue"> Upload Invoice </span>
        </div>
      </template>
      <div
        @dragover.prevent
        @drop.prevent
      >
        <b-form-group
          label-for="upload-poc"
          class="w-100"
        >
          <template #label>
            <span class="color-neutral-black font-size-16-px">Upload Invoice
              <span class="text-danger font-small-4">*</span></span>
            <div
              v-if="!orderInvoiceFile"
              class="fileDownload poc-upload"
              @drop="onDropOrderInvoiceFile"
            >
              <upload-icon />
              <h4 class="font-weight-bolder">
                <b>Drag & Drop</b>
              </h4>
              <h6>or click to select files from your system</h6>
            </div>
          </template>
          <div
            v-if="orderInvoiceFile"
            class="file-uploaded mt-50"
            @drop="onDropOrderInvoiceFile"
          >
            <div class="elements-center">
              <b-img
                :src="pdfIcon"
                class="mx-1"
                alt="ErrorIcon"
              />
              <div>
                <div>Name: {{ orderInvoiceFile.name }}</div>
                <div>
                  Size:
                  {{ (formatNumber(orderInvoiceFile.size / 1024), 2) }} KB
                </div>
              </div>
            </div>
            <div @click="orderInvoiceFile = null">
              <delete-icon />
            </div>
          </div>
          <b-form-file
            v-if="!orderInvoiceFile"
            id="upload-poc"
            v-model="orderInvoiceFile"
            name="upload-poc"
            :hidden="true"
            plain
          />
        </b-form-group>
      </div>

      <div class="elements-center p-1">
        <b-button
          variant="outline-secondary-info mr-1"
          @click="$bvModal.hide('upload_order_invoice')"
        >
          Cancel
        </b-button>
        <b-button
          variant="secondary-info"
          :disabled="!orderInvoiceFile || isUploading"
          @click="updateOrderInvoiceFile()"
        >
          {{ isUploading ? 'Uploading' : 'Upload' }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormFile,
  BButton,
  BImg,
} from 'bootstrap-vue'
import {
  formatNumber,
} from '@/@core/utils/utils'
import store from '@/store'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import UploadIcon from '@/@core/assets/svg-components/UploadIcon.vue'
import { FILE_TYPES } from '@/constants'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import DeleteIcon from '@/@core/assets/svg-components/DeleteIcon.vue'
import { UPDATE_ORDER } from '@/store/modules/order.module'

export default {
  name: 'OrderUploadInvoice',
  components: {
    BModal,
    UploadIcon,
    BFormFile,
    BFormGroup,
    BButton,
    BImg,
    DeleteIcon,
},
  directives: {
    'b-modal': VBModal,
  },
  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
    loadOrder: {
      type: Function,
      default() {

      },
    },
  },
  data() {
    return {
      pdfIcon: require('@/assets/images/icons/pdficon.svg'),
      formatNumber,
      orderInvoiceFile: null,
      orderInvoiceFileLocation: null,
      isUploading: false,
      FILE_TYPES,
    }
  },
  methods: {
    onDropOrderInvoiceFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      this.orderInvoiceFile = droppedFile
    },
    async updateOrderInvoiceFile() {
      this.isUploading = true
      try {
        if (this.orderInvoiceFile instanceof File) {
          this.orderInvoiceFileLocation = await store.dispatch(UPLOAD_DOCUMENTS, {
            fileType: FILE_TYPES.ORDER_INVOICE,
            file: this.orderInvoiceFile,
          })
        }
        const payload = {
          invoiceUrl: this.orderInvoiceFileLocation,
        }
        await store.dispatch(UPDATE_ORDER, {
          orderId: this.order._id,
          payload,
        })
        apiToastSuccess('Your Invoice document has been uploaded successfully')
        this.clearForm()
        this.$bvModal.hide('upload_order_invoice')
        this.loadOrder()
      } catch (err) {
        apiToastError(err)
      } finally {
        this.isUploading = false
      }
    },
    clearForm() {
      this.orderInvoiceFile = null
      this.orderInvoiceFileLocation = null
    },
    onClickOrderUploadInvoice() {
      this.$bvModal.show('upload_order_invoice')
    },
  },
}
</script>
