<template>
  <div>
    <b-row class="sticky-input sticky-input-popup bg-white mt-2">
      <b-col class="col-6">
        <b-form-input
          placeholder="Search by products, style code..."
          @input="searchProducts"
        />
        <feather-icon
          icon="SearchIcon"
          size="18"
          class="cart-search-icon"
        />
      </b-col>
      <b-col class="col-6 d-flex justify-content-end">
        <b-button
          v-if="!!payloadProducts.length"
          variant="info"
          class="font-weight-bolder"
          @click="onClickAddToOrder"
        >
          <span v-if="!isAddingProducts"> Add to the order </span>
          <b-spinner
            v-else
            small
          />
        </b-button>
      </b-col>
    </b-row>
    <template v-if="!!shopProducts.length">
      <div
        id="add_order_products_container"
        class="py-2 add-order-products-container"
        @scroll="loadMoreProducts"
      >
        <b-row
          v-for="product in shopProducts"
          :key="product._id"
          class="mx-0 mb-1 cart-product"
          no-body
          :class="{
            'has-error': product.hasError,
            'border-info-thin': selectedProductIds.includes(product._id),
          }"
        >
          <!-- Product Image -->
          <b-col class="item-img col-md-3 col-xl-2 pl-0 col-12">
            <b-link
              :to="{
                name: 'product-detail',
                params: { id: product._id, collectionId: product.collectionId },
              }"
            >
              <b-img-lazy
                class="imgcss"
                :src="useEcommerceUi().parseProductImage(product)"
                :alt="`${product.name}-${product._id}`"
                @error.native="imageLoadError"
              />
            </b-link>
          </b-col>

          <!-- Product Details: Card Body -->
          <b-col
            class="col-md-7 col-xl-8 border-right pt-2 d-flex flex-column col-12"
          >
            <div class="item-name d-flex justify-content-between">
              <div>
                <b-link
                  class="product-link"
                  :to="{
                    name: 'product-detail',
                    params: {
                      id: product._id,
                      collectionId: product.collectionId,
                    },
                  }"
                >
                  {{ product.name }}
                </b-link>
                <b-link class="company-name">
                  <b-breadcrumb
                    class="breadcrumb-products mb-0 pl-0"
                    :items="[
                      product.department,
                      product.category,
                      product.subCategory,
                    ]"
                  />
                </b-link>
                <div class="cart-price-title">
                  <div class="mt-75">
                    Style Code:
                    <span class="cart-price-value">{{
                      product.styleCode
                    }}</span>
                  </div>
                  <div>
                    Retail Price:
                    <span class="cart-price-value">{{
                      formatCurrency(product.retailPrice, product.currency)
                    }}</span>
                  </div>
                  <div>
                    Wholesale Selling Price:
                    <span
                      v-if="!product.discountedPrice"
                      class="cart-price-value"
                      :class="{
                        'text-line-through color-red':
                          !!product.discountedPrice,
                      }"
                    >
                      {{ formatCurrency(product.price, product.currency) }}
                    </span>
                    <span
                      v-if="product.discountedPrice"
                      class="cart-price-value discounted-price"
                    >
                      {{
                        formatCurrency(
                          product.discountedPrice,
                          product.currency
                        )
                      }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <div class="font-weight-normal mt-50">
                  <span
                    v-if="product.shippingStartDate && product.shippingEndDate"
                    class="mb-2 cart-price-title"
                  >
                    Availability Date:
                    <span
                      class="cart-price-value"
                    >{{ formattedDate(product.shippingStartDate) }} to
                      {{ formattedDate(product.shippingEndDate) }}</span>
                  </span>
                </div>
                <div
                  v-if="product.isCarton"
                  class="item-quantity mt-1 mr-1"
                >
                  <span class="cart-price-title">Qty:</span>
                  <b-form-spinbutton
                    v-model="product.quantity"
                    size="sm"
                    class="ml-75"
                    inline
                    :max="getMaxQuantity(product.quantityAvailable)"
                    :disabled="
                      isProductValueDisabled(product.quantityAvailable)
                    "
                    min="1"
                    @input="updateQuantity(product)"
                  />
                  <div
                    v-if="hasProductMaxQuantity(product.quantityAvailable)"
                    class="text-nowrap text-right color-actions-text-grey mt-2-px mr-1"
                  >
                    Max Qty: {{ product.quantityAvailable }}
                  </div>
                </div>
              </div>
            </div>

            <!-- variants -->
            <product-variants
              :is-deleted="product.isDeleted"
              :is-carton="product.isCarton"
              :items="product.items"
              :is-edit-enabled="true"
              @update-variants="
                (variants) => updateProductVariants(variants, product)
              "
            />
          </b-col>

          <!-- Product Options/Actions -->
          <b-col
            class="item-options text-center d-flex flex-column flex-md-column md-0 ml-md-auto col-md-2 col-xl-2 align-items-center mt-2 col-12"
          >
            <div class="ml-auto mr-1">
              <b-card-text>
                <b-form-checkbox
                  :id="product._id"
                  class="shipment-checkbox"
                  :checked="selectedProductIds.includes(product._id)"
                  :checked-value="true"
                  :unchecked-value="false"
                  @change="onSelectProduct($event, product)"
                />
              </b-card-text>
            </div>

            <div class="item-wrapper my-2 my-md-auto ml-auto ml-md-0">
              <div class="item-cost text-center d-flex d-md-block">
                <h4
                  v-if="variantLoaded"
                  class="item-price mr-50 mr-md-0 font-weight-bolder color-neutral-black"
                >
                  Total Value:
                  {{
                    formatCurrency(
                      product.lineTotalPriceWithDiscount ||
                        product.lineTotalPrice ||
                        0,
                      product.currency
                    )
                  }}
                </h4>
                <b-card-text
                  v-if="variantLoaded"
                  class="mt-2"
                >
                  Total Units:
                  {{ formatNumber(product.lineTotalQuantity || 0) }}
                </b-card-text>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
    <template v-else>
      <div v-if="isLoadingProducts">
        <div class="w-100 text-center p-4">
          <b-spinner />
        </div>
      </div>
      <div
        v-else
        class="w-100 text-center py-4"
      >
        No products found
      </div>
    </template>
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BButton,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormSpinbutton,
  BImgLazy,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import {
  formatNumber,
  getMaxQuantity,
  hasProductMaxQuantity,
  imageLoadError,
  isProductValueDisabled,
} from '@/@core/utils/utils'
import { utils, productUtil } from '@kingpin-global/kingpin-utils-frontend'
import { formatDate } from '@/@core/utils/filter'

import { FETCH_COLLECTION_PRODUCTS } from '@/store/modules/collection.module'
import { ADD_ORDER_PRODUCTS } from '@/store/modules/order.module'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import ProductVariants from '../product/detail/ProductVariants.vue'
import { useEcommerceUi } from '../useEcommerce'
import { parseProductItems } from '../checkout/cart-products-utils'

const { getProductAndCartTotals } = productUtil

const { formatCurrency, formattedDate } = utils

export default {
  name: 'AddOrderProducts',
  components: {
    BRow,
    BCol,
    BFormInput,
    FeatherIcon,
    BCardText,
    ProductVariants,
    BFormSpinbutton,
    BLink,
    BBreadcrumb,
    BImgLazy,
    BFormCheckbox,
    BSpinner,
    BButton,
  },
  props: {
    order: {
      type: Object,
      default() {
        return null
      },
    },
    collectionId: {
      type: String,
      default() {
        return ''
      },
    },
    addProductModalId: {
      type: String,
      default() {
        return 'add_products_modal'
      },
    },
    loadOrder: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      shopProducts: [],
      isLoadingProducts: false,
      formatNumber,
      formatCurrency,
      formattedDate,
      useEcommerceUi,
      hasProductMaxQuantity,
      getMaxQuantity,
      imageLoadError,
      isProductValueDisabled,
      formatDate,
      variantLoaded: false,
      searchText: '',
      pageNumber: 0,
      totalProductsCount: 0,
      selectedProductIds: [],
      payloadProducts: [],
      isAddingProducts: false,
      debounce: null,
      existingProductIds: [],
    }
  },
  created() {
    if (this.order) {
      this.existingProductIds = this.order.products.map(p => p.productId)
    }
    if (this.collectionId) {
      this.loadProducts()
    }
  },
  methods: {
    onClickAddToOrder() {
      let hasNoValuesInANonCartonProduct = false
      this.payloadProducts.forEach(_p => {
        if (_p.items && _p.items.length && !hasNoValuesInANonCartonProduct) {
          hasNoValuesInANonCartonProduct = _p.items.every(item => item.value === 0)
        }
      })

      if (hasNoValuesInANonCartonProduct) {
        apiToastWarning('Before proceeding, please verify that there are no values for some selected products.')
        return
      }

      this.isAddingProducts = true
      this.$store
        .dispatch(ADD_ORDER_PRODUCTS, {
          orderId: this.order._id,
          payload: { products: this.payloadProducts },
        })
        .then(res => {
          apiToastSuccess(res.data.message || 'Products added successfully!')
          this.isAddingProducts = false
          this.$bvModal.hide(this.addProductModalId)
          this.loadOrder()
        })
        .catch(err => {
          apiToastWarning(err)
          this.isAddingProducts = false
        })
    },
    loadMoreProducts() {
      this.$nextTick(() => {
        const scrollableDiv = document.getElementById(
          'add_order_products_container',
        )
        // Check if scrollableDiv is not null
        if (scrollableDiv) {
          // Check if the user has scrolled to the bottom of the scrollable area
          if (
            scrollableDiv.scrollTop + scrollableDiv.clientHeight
            >= scrollableDiv.scrollHeight
          ) {
            // Check if there are more pages to load
            if (this.shopProducts.length < this.totalProductsCount) {
              this.pageNumber++
              // Load more content (e.g., make an API request to fetch more data)
              this.loadProducts()
            }
          }
        }
      })
    },
    loadProducts() {
      this.isLoadingProducts = true
      const queryParams = {
        collectionId: this.collectionId,
        search: this.searchText,
        page: this.pageNumber,
        limit: 10,
      }
      this.$store
        .dispatch(FETCH_COLLECTION_PRODUCTS, queryParams)
        .then(res => {
          const products = []
          const responseData = res.data?.data
          const responseProducts = responseData?.products?.[0]?.products ?? []
          this.totalProductsCount = responseData.count
          responseProducts.forEach(product => {
            product.quantity = product.quantity || 1
            if (!this.existingProductIds.includes(product._id)) {
              products.push(product)
            }
          })
          this.shopProducts = [...this.shopProducts, ...products]
          this.isLoadingProducts = false
        })
        .catch(() => {
          this.isLoadingProducts = false
        })
    },
    onSelectProduct(isSelectOrDeslect, product) {
      if (isSelectOrDeslect) {
        this.selectedProductIds.push(product._id)
        this.addProductToPayload(product, true)
      } else {
        this.selectedProductIds = this.selectedProductIds.filter(
          id => id !== product._id,
        )
        this.removeProductFromPayload(product)
      }
    },
    searchProducts(text) {
      this.searchText = text
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.pageNumber = 0
        this.shopProducts = []
        this.loadProducts()
      }, 500)
    },
    updateQuantity(product) {
      const index = this.shopProducts.findIndex(p => p._id === product._id)
      this.shopProducts[index].quantity = product.quantity
      this.updateTotalPriceAndUnits(product)
    },

    updateProductVariants(variants, product) {
      product.variants = variants
      const items = parseProductItems(product)
      product.items = items
      const index = this.shopProducts.findIndex(p => p._id === product._id)
      this.shopProducts[index].items = items

      if (!product.isCarton) {
        this.shopProducts[index].hasError = product?.hasError || false
      }
      this.updateTotalPriceAndUnits(product)
      this.variantLoaded = true
    },
    updateTotalPriceAndUnits(product) {
      getProductAndCartTotals(this.shopProducts, this.shopProducts[0].discount)
      this.addProductToPayload(product)
    },
    addProductToPayload(product, isAdd = false) {
      const isProductAlreadySelected = this.selectedProductIds.find(
        id => id === product._id,
      )
      let payloadProduct = null
      if (product.isCarton) {
        payloadProduct = {
          productId: product._id,
          quantity: product.quantity,
        }
      } else if (
        Array.isArray(product.variants)
        && product.variants.length > 0
      ) {
        const payloadProductsItems = []
        product.variants.forEach(item => {
          for (const k in item) {
            if (item.hasOwnProperty(k) && k !== 'color') {
              payloadProductsItems.push({
                sku: item[k].skuId,
                value: item[k].value,
              })
            }
          }
        })
        payloadProduct = {
          productId: product._id,
          items: payloadProductsItems,
        }
      }
      if (payloadProduct) {
        if (isAdd) {
          this.payloadProducts.push(payloadProduct)
        } else if (isProductAlreadySelected) {
          const index = this.payloadProducts.findIndex(
            p => p.productId === product._id,
          )
          this.payloadProducts[index] = payloadProduct
        }
      }
    },
    removeProductFromPayload(product) {
      this.payloadProducts = this.payloadProducts.filter(
        payloadProduct => payloadProduct.productId !== product._id,
      )
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-cart-products.scss';

.add-order-products-container {
  height: 800px;
  overflow-y: scroll;
}
</style>
