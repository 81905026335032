<template>
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 4.5L0.5 8.5L0.5 0.5L5.5 4.5Z"
      fill="#373D6E"
    />
  </svg>
</template>
<script>
export default {
  name: 'CollapseIcon',
}
</script>
