<template>
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8 1.13333L4.46667 8.46667C4.33333 8.6 4.2 8.66667 4 8.66667C3.8 8.66667 3.66667 8.6 3.53333 8.46667L0.2 5.13333C-0.0666667 4.86667 -0.0666667 4.46667 0.2 4.2C0.466667 3.93333 0.866667 3.93333 1.13333 4.2L4 7.06667L10.8667 0.2C11.1333 -0.0666667 11.5333 -0.0666667 11.8 0.2C12.0667 0.466667 12.0667 0.866667 11.8 1.13333Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'TickIcon',
}
</script>
