<template>
  <b-button-group
    class="split-action-dropdown"
  >
    <b-button
      v-if="!(disableButton && hideDropdownOptions)"
      :disabled="disableButton"
      variant="dark-grey"
      @click="onClick"
    >
      <b-img
        :src="icon"
        class="dropdown-item-icon"
      /> {{ label }}
    </b-button>
    <b-dropdown
      v-if="!hideDropdownOptions"
      right
      variant="dark-grey"
      toggle-class="dropdown-toggle-split w-100"
    >
      <div
        :style="{'min-width': dropdownMenuWidth}"
      >
        <slot />
      </div>
    </b-dropdown>
  </b-button-group>
</template>

<script>
import {
    BDropdown,
    BButton,
    BButtonGroup,
    BImg,
} from 'bootstrap-vue'

export default {
  name: 'SplitActionButton',
  components: {
    BDropdown,
    BImg,
    BButton,
    BButtonGroup,
  },
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    hideDropdownOptions: { type: Boolean, default: false },
    disableButton: { type: Boolean, default: false },
    dropdownMenuWidth: { type: String, default: '222px' },
  },
  setup(_, context) {
    // Dropdown Section
    const onClick = e => {
      context.emit('click', e)
    }

    return {
      onClick,
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.btn-dark-grey {
    background: $dark-grey;
    color: $white;

    &:hover {
        color: $white;
    }
}
.split-action-dropdown {

    .btn-group {
        margin-left: 1px !important;
    }

    .dropdown-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            background: $hover-bg;
        }
        &.active {
            background: $bright-gray;
        }

        .btn-svg-icon {
            margin-right: 10px !important;
            height: 14px !important;
        }
    }

    .dropdown-item-icon {
        margin-right: 0.5rem;
        height: 14px !important;
    }

    .dropdown-menu {
        margin-top: 0.1rem;
    }
}
</style>
