<template>
  <b-row>
    <template v-for="(value, key) in options">
      <b-col
        v-if="value && value.length"
        :key="key"
        lg="2"
        md="3"
        sm="4"
        cols="6"
        class="mb-1 pl-5-px"
      >
        <b-dropdown
          class="filter-dropdown ml-8-px"
          variant="outline-none"
          block
          no-caret
          menu-class="w-100 filter-dropdown-menu scroll-bar"
          toggle-class="filter-dropdown-toggle"
        >
          <template #button-content>
            <div
              class="d-flex justify-content-between"
              :class="{
                'has-value': getFilterOptionsCount(selectedOptions[key]),
              }"
            >
              <span class="text-capitalize">
                {{ toTitleCase(key) }}
                {{
                  getFilterOptionsCount(selectedOptions[key]) &&
                    `(${getFilterOptionsCount(selectedOptions[key])})`
                }}
              </span>
              <feather-icon icon="ChevronDownIcon" />
            </div>
          </template>
          <b-dropdown-item
            v-for="option in value"
            :key="option"
            :active="selectedOptions[key].includes(option)"
            @click="onSelectOption(key, option)"
          >
            <div class="d-flex justify-content-between option-item">
              <span>
                {{ textTruncate(toTitleCase(option), true, 25, 22) }}
              </span>
              <span class="option-selected-icon">
                <tick-icon v-if="selectedOptions[key].includes(option)" />
              </span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </template>
  </b-row>
</template>

<script>
import {
 BCol, BDropdown, BDropdownItem, BRow,
} from 'bootstrap-vue'
import TickIcon from '@/@core/assets/svg-components/TickIcon.vue'
import { textTruncate, toTitleCase } from '@/@core/utils/utils'

const filterDropdownKey = {
  STATUS: 'status',
  DEPARTMENT: 'department',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
}

const MODIFIED_ITEMS = 'Modified items'
export default {
  name: 'OrderProductsFilter',
  components: {
    BDropdown,
    BDropdownItem,
    TickIcon,
    BRow,
    BCol,
  },
  props: {
    orderProducts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      // Dropdown options
      options: {},
      // Selected options
      selectedOptions: {},
      // Filtered products result
      filteredProducts: [],
      // functions
      toTitleCase,
      textTruncate,
    }
  },
  emits: ['on-apply-filter'],
  computed: {
    // This property returns true if there are no selected filter options, and false otherwise
    isFiltersEmpty() {
      return !Object.values(this.selectedOptions).some(
        selectedOp => selectedOp?.length,
      )
    },
  },
  created() {
    this.initializeFilterOptions()
  },
  methods: {
    /**
     * Initializes filter options and sets the initial, default and selected options
     */
    initializeFilterOptions() {
      // Get the filter options from all order products
      this.options = this.getFilterOptionValues(this.orderProducts)
      // Set the selected options to empty
      this.selectedOptions = this.getBuildDefaultOptions()
    },
    /**
     * Returns an object of build default options with empty values
     */
    getBuildDefaultOptions() {
      // Create an empty object
      const optionsObj = {}
      // Loop through all filter dropdown keys and set each to an empty array
      Object.values(filterDropdownKey).forEach(opKey => {
        optionsObj[opKey] = []
      })
      return optionsObj
    },
    /**
     * Returns the count of options or an empty string
     */
    getFilterOptionsCount(options = []) {
      return options?.length || ''
    },
    /**
     * Returns an object with all filter options and their values
     */
    getFilterOptionValues(products = []) {
      const options = this.getBuildDefaultOptions()
      products.forEach(orderProduct => {
        Object.keys(options).forEach(opKey => {
          const opValue = orderProduct?.[opKey]
          if (opValue && !options[opKey].includes(opValue)) {
            options[opKey].push(opValue)
          }
        })
      })
      options[filterDropdownKey.STATUS].push(MODIFIED_ITEMS)
      return options
    },
    /**
     * Updates the selected options when a filter dropdown option is selected
     */
    onSelectOption(dropdownKey = '', value = '') {
      if (dropdownKey && value) {
        if (!this.selectedOptions[dropdownKey].includes(value)) {
          this.selectedOptions[dropdownKey].push(value)
        } else {
          this.selectedOptions[dropdownKey] = this.selectedOptions[
            dropdownKey
          ].filter(selectedCategory => selectedCategory !== value)
        }
        this.filterProducts()
      }
    },
    /**
     * Filters the products based on the selected options and updates the options
     */
    filterProducts() {
      this.filteredProducts = []
      let filteringProducts = this.orderProducts
      Object.keys(this.selectedOptions).forEach(opKey => {
        if (this.selectedOptions?.[opKey].length) {
          this.filteredProducts = filteringProducts.filter(orderProduct => {
            const filteringKey = this.selectedOptions?.[opKey].filter(_opKey => _opKey !== MODIFIED_ITEMS)
            const isItemIncluded = filteringKey.includes(orderProduct[opKey])
            if (opKey === filterDropdownKey.STATUS && this.selectedOptions?.[opKey].includes(MODIFIED_ITEMS)) {
              return orderProduct.isEdited || isItemIncluded
            }
            return isItemIncluded
          })
          filteringProducts = this.filteredProducts
        }
      })
      if (this.isFiltersEmpty) {
        this.$emit('on-apply-filter', false)
      } else {
        this.$emit('on-apply-filter', true, this.filteredProducts)
      }
    },
  },
}
</script>

<style lang="scss">
.option-item {
  position: relative;
  .option-selected-icon {
    position: absolute;
    right: 1px;
    top: 5%;
  }
}
</style>
