import {
    computed,
    ref,
} from '@vue/composition-api'

import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import { isProductItemValid } from '@/@core/utils/utils'

const { PRODUCT_STATUS, ROLES } = c

// Helper Functions
export function initializeData(root) {
    const variantLoaded = ref(false)
    const isLoaded = ref(false)
    const products = ref([])
    const productsSlice = ref([])
    const searchText = ref(null)
    const originalProducts = ref([])
    const rawProducts = ref([])
    const collectionEndDate = ref(null)
    const discountInCredit = ref(0)
    const collectionName = ref('')
    const collectionId = ref('')
    const isBrand = computed(() => root.$store.getters.entityType === ROLES.BRAND)
    const isCartExport = ref(false)
    return {
        variantLoaded,
        isLoaded,
        products,
        productsSlice,
        searchText,
        originalProducts,
        rawProducts,
        collectionEndDate,
        discountInCredit,
        collectionName,
        collectionId,
        isBrand,
        isCartExport,
    }
}

export function isNotValidMinQty(product, quantity = 0) {
    if (product?.minQtyPerStyle) {
        return quantity && product.minQtyPerStyle > quantity
    } else {
        let isNotValidMinQty = false
        if (!product?.isCarton && product?.minQtyPerSize) {
            product.items.forEach(item => {
                if (item.value && item.value < product.minQtyPerSize) {
                    isNotValidMinQty = true
                }
            })
        }
        return isNotValidMinQty
    }
}

export function minQtyErrorMessage(product) {
    let minQtyText = ''
    if (product?.minQtyPerStyle) {
        minQtyText = `style: ${product.minQtyPerStyle}`
    } else if (product?.minQtyPerSize) {
        minQtyText = `size: ${product.minQtyPerSize}`
    }
    return minQtyText ? `MOQ per ${minQtyText}` : ''
}

export function calculateSizeTotalValues(items) {
    return items.reduce((total, item) => total + item.value, 0)
}

// parse product item to use on variant components
export const parseProductItems = product => {
    // return default items
    const currentItems = {}
    product.variants.forEach(item => {
      for (const { skuId, value } of Object.values(item)) {
        if (skuId) {
          currentItems[skuId] = value
        }
      }
    })
    let result
    if (!product.isCarton && Object.keys(currentItems).length) {
      product.hasError = false
      result = product.items.map(item => {
        const { skuId, sku } = item
        item.value = currentItems[skuId || sku] || 0
        if (
          !product.isCarton
          && isProductItemValid(item.value, item.quantityAvailable)
        ) {
          product.hasError = true
        }
        return item
      })
    } else {
      result = product.items
    }
    return result
  }

  export const getCartProductsErrorStatusText = product => {
    let status = ''
    if (product.status === PRODUCT_STATUS.CANCELLED.VALUE) {
      status = PRODUCT_STATUS.CANCELLED.LABEL
    } else if (product?.hasError) {
      status = 'Out of Stock'
    } else if (!product?.lineTotalQuantity) {
      status = '0 Quantities'
    }
    return status
  }

  const getCartProductsOrderValue = product => {
    if (product.status === PRODUCT_STATUS.CANCELLED.VALUE) {
        return 1
    } else if (product?.hasError) {
        return 2
    } else if (!product?.lineTotalQuantity) {
        return 3
    }
    return 4
  }

  export const getSortedCartProducts = products => products
      .sort((a, b) => getCartProductsOrderValue(a) - getCartProductsOrderValue(b))

  export const isProductHasZeroQty = product => !product?.lineTotalQuantity && product.status !== PRODUCT_STATUS.CANCELLED.VALUE

export function processProductVariants(prod, originalProduct, payload, isBrand) {
  const payloadProductsItems = []
  let updatedItemsCount = 0
  prod.variants.forEach(item => {
    for (const k in item) {
      if (item.hasOwnProperty(k) && k !== 'color') {
        const itemFromOriginalProduct = originalProduct.items.find(
          i => i.sku === item[k].skuId,
        )
        payloadProductsItems.push({
          sku: item[k].skuId,
          value: item[k].value || 0,
        })
        if (itemFromOriginalProduct.value !== item[k].value) {
          updatedItemsCount++
        }
      }
    }
  })

  if (updatedItemsCount > 0 || isBrand.value) {
    const payloadProducts = [
      {
        productId: prod._id,
        items: payloadProductsItems,
      },
    ]
    payload.products.push(...payloadProducts)
  }
}
