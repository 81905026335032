<template>
  <div class="checkout-items">
    <!-- loaded -->
    <template v-if="isLoaded">
      <!-- has product -->
      <template v-if="productsSlice.length">
        <div
          v-if="order && order.isEnableShipmentFeature && order.readyToShipProductsCount && !isShipment"
          class="w-100 d-flex justify-content-end mb-2"
        >
          <div
            v-if="order.readyToShipProductsCount > 1"
            class="mr-1"
          >
            <b-form-checkbox
              class="mt-1"
              :checked-value="true"
              :unchecked-value="false"
              :checked="isSelectedAll()"
              @change="onSelectReadyToShipProducts($event)"
            >
              Select Ready To Ship Products
            </b-form-checkbox>
          </div>
          <div>
            <b-button
              class="border-rounded"
              variant="info"
              :disabled="!isSelectedProduct"
              @click="shipProducts"
            >
              Ship Now
            </b-button>
          </div>
        </div>
        <b-row
          v-for="(product, index) in productsSlice"
          :key="product.productId"
          class="mx-0"
          :class="{
            'cart-product mb-2 border-info-thin':
              product.status ===
              ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL,
            'cart-product mb-2':
              product.status !==
              ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL,
            'has-error' : isRemoveEnabled(product) && product.hasError,
          }"
          no-body
        >
          <!-- Product Image -->
          <b-col class="item-img col-md-3 col-xl-2 pl-0 col-12">
            <b-link
              :to="{name: 'product-detail', params: {id: product.productId, collectionId:order.collectionId}}"
            >
              <b-img-lazy
                class="imgcss"
                :src="parseProductImage(product)"
                :alt="`${product.name}-${product.productId}`"
                @error.native="imageLoadError"
              />
            </b-link>
            <span
              v-if="product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL && (product.isDeletedByRetailer || product.isDeletedByBrand)"
              class="d-block text-center msgposition remove-span"
            >
              This product is removed by {{ product.isDeletedByRetailer ? 'the retailer' : 'the brand' }}.
            </span>
            <span
              v-if="!(product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL) && product.isEdited"
              class="d-block text-center msgposition modify-span"
            >
              This product is modified by the brand.
            </span>
          </b-col>

          <!-- Product Details: Card Body -->
          <b-col class="col-md-7 col-xl-8 border-right pt-2 d-flex flex-column col-12">
            <div class="item-name d-flex justify-content-between">
              <div>
                <b-link
                  class="product-link"
                  :to="{name: 'product-detail', params: {id: product.productId, collectionId:order.collectionId}}"
                >
                  {{ product.name }}
                </b-link>
                <b-link class="company-name">
                  <b-breadcrumb
                    class="breadcrumb-products mb-0 pl-0"
                    :items="[product.department, product.category, product.subCategory]"
                  />
                </b-link>
                <div class="cart-price-title">
                  <div class="mt-75">
                    Style Code: <span class="cart-price-value">{{ product.styleCode }}</span>
                  </div>
                  <div>
                    Retail Price: <span class="cart-price-value">{{ formatCurrency(product.retailPrice, currency) }}</span>
                  </div>
                  <div>
                    Wholesale Selling Price: <span class="cart-price-value">{{ formatCurrency(product.price, currency) }}</span>
                  </div>
                  <div v-if="order && !isRetailer && product.purchasePrice">
                    Kingpin Purchase Price:
                    <span class="cart-price-value">{{ formatCurrency(product.purchasePrice, currency) }}</span>
                  </div>
                </div>
              </div>
              <div class="text-right">
                <div
                  v-if="order && isShowStatusTag(product.status)"
                  class="text-light font-weight-bold d-inline-flex"
                >
                  <b-card-text
                    class="product-status-tag"
                    :style="{ background: getStatusTextAndColorCode(product.status, order).colorCode }"
                  >
                    {{ getStatusTextAndColorCode(product.status, order).text }}
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="18"
                    />
                  </b-card-text>
                </div>
                <div class="font-weight-normal mt-50">
                  <span
                    v-if="product.shippingStartDate && product.shippingEndDate"
                    class="mb-2 cart-price-title"
                  >
                    Availability Date:
                    <span class="cart-price-value">{{ formattedDate(product.shippingStartDate) }} to {{ formattedDate(product.shippingEndDate) }}</span>
                  </span>
                </div>
                <div
                  v-if="product.isCarton"
                  class="item-quantity mt-1 mr-1"
                >
                  <span class="cart-price-title">Qty:</span>
                  <b-form-spinbutton
                    v-model="product.quantity"
                    size="sm"
                    class="ml-75"
                    inline
                    :max="getMaxQuantity(product.quantityAvailable)"
                    :disabled="
                      product.status ===
                        ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL ||
                        product.isEdited ||
                        isEditEnabled || isProductValueDisabled(product.quantityAvailable)
                    "
                    @input="updateTotalPriceAndUnits()"
                  />
                  <div
                    v-if="isEditEnabled && hasProductMaxQuantity(product.quantityAvailable)"
                    class="text-nowrap text-right color-actions-text-grey mt-2-px mr-1"
                  >
                    Max Qty: {{ product.quantityAvailable }} </div>
                </div>
              </div>
            </div>

            <!-- variants -->
            <product-variants
              :disabled-product="isProductDisabled(product)"
              :is-carton="product.isCarton"
              :items="!isShipment ? product.items : product.itemsForShipment || product.items"
              :is-modify-enabled-brand="!isEditEnabled"
              @update-variants="
                (variants) => updateProductVariants(variants, product)
              "
            />
          </b-col>

          <!-- Product Options/Actions -->
          <b-col class="item-options text-center d-flex flex-column flex-md-column md-0 ml-md-auto col-md-2 col-xl-2 align-items-center mt-2 col-12">
            <div
              v-if="order && order.isEnableShipmentFeature"
              class="ml-auto mr-1"
            >
              <b-card-text v-if="getStatusTextAndColorCode(product.status).isEnabled">
                <b-form-checkbox
                  :id="isShipment ? product.productId + 's' : product.productId"
                  class="shipment-checkbox"
                  :checked="
                    product.status ===
                      ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
                  "
                  :checked-value="true"
                  :unchecked-value="false"
                  @change="onSelect($event, product, index)"
                />
              </b-card-text>
            </div>
            <template v-if="order">
              <b-button
                v-if="isRemoveEnabled(product)"
                v-b-modal="`modal-remove-product-${product.productId}`"
                variant="danger"
                class="p-0 mt-1 remove-wishlist remove-button-height w-75"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-1"
                />
                <span>Remove</span>
              </b-button>
            </template>
            <b-modal
              :id="`modal-remove-product-${product.productId}`"
              ok-variant="danger"
              ok-title="Remove product"
              cancel-title="Dismiss"
              modal-class="modal-danger"
              centered
              title="Remove this product?"
              @ok="$emit('remove-product', product, index)"
            >
              <b-card-text>
                Are you sure you want to delete this product?
              </b-card-text>
            </b-modal>

            <div class="item-wrapper my-2 my-md-auto ml-auto ml-md-0">
              <div class="item-cost text-center d-flex d-md-block">
                <h4
                  v-if="variantLoaded"
                  class="item-price mr-50 mr-md-0  font-weight-bolder color-neutral-black"
                >
                  Total Value:
                  {{ formatCurrency(product.lineTotalPrice, currency) }}
                </h4>
                <b-card-text
                  v-if="variantLoaded"
                  class="mt-2"
                >
                  Total Units:
                  {{ formatNumber(product.lineTotalQuantity || 0) }}
                </b-card-text>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
      <!-- empty order products -->
      <template v-else>
        <b-card
          :title="isFilterApplied ? 'No products found' : 'No Products Added'"
        >
          {{ isFilterApplied ? 'No matches were found. Try a new filter.' : 'No products in your order' }}
        </b-card>
      </template>
    </template>
    <!-- loading -->
    <template v-else>
      <b-card class="text-center p-4">
        <b-spinner />
      </b-card>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BBreadcrumb,
  BButton,
  BCard,
  BCardText,
  BFormCheckbox,
  BFormSpinbutton,
  BImgLazy,
  BLink,
  BSpinner,
  BRow,
  BCol,
  BModal,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import { utils, constants as c, productUtil } from '@kingpin-global/kingpin-utils-frontend'
import {
  ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import ProductVariants from '@/views/apps/product/detail/ProductVariants.vue'
import {
 formatNumber, hasProductMaxQuantity, isProductValueDisabled, getMaxQuantity, imageLoadError,
} from '@/@core/utils/utils'
import { getStatusTextAndColorCode } from '@/common-utils'
import constants, { PRODUCTS_LIMIT } from '@/constants'

import { formatDate } from '@/@core/utils/filter'

import store from '@/store'
import { useEcommerceUi } from '../useEcommerce'
import UserRoleMixinVue from '../UserRoleMixin.vue'
import { parseProductItems } from '../checkout/cart-products-utils'

const { getProductAndOrderTotals } = productUtil
const { formatCurrency, formattedDate } = utils
const { ORDER_STATUS, SHIPMENT_STATUS } = c
const { ORDER_PRODUCT_SHIPMENT_STATUS } = constants

export default {
  name: 'OrderProducts',
  components: {
    BCard,
    BCardText,
    BFormCheckbox,
    BLink,
    BImgLazy,
    BButton,
    BFormSpinbutton,
    BBreadcrumb,
    BSpinner,
    BRow,
    BCol,
    ProductVariants,
    BModal,
},
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixinVue],
  props: {
    order: {
      type: Object,
      default() {
        return null
      },
    },
    orderProducts: {
      type: Array,
      default() {
        return null
      },
    },
    isShipment: {
      type: Boolean,
      default() {
        return false
      },
    },
    isModifyEnabled: {
      type: Boolean,
      default() {
        return false
      },
    },
    isFilterApplied: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      getStatusTextAndColorCode,
      ORDER_PRODUCT_SHIPMENT_STATUS,
      formatDate,
      isProductValueDisabled,
      hasProductMaxQuantity,
      getMaxQuantity,
      imageLoadError,
    }
  },
  computed: {
    currency() {
      if (this.isBrand) {
        return this.order.currencyForBrand
      }
      return this.order.currency
    },
    isEditEnabled() {
      if (this.order) {
        return !this.isModifyEnabled
      }
      return this.isShipment
    },
  },
  watch: {
    // Watching the variable when filter applied
    orderProducts() {
      this.setOrderProducts()
    },
  },
  methods: {
    isProductDisabled(product) {
      return this.isShipment || product.isDeleted || product.isDeletedByBrand || product.isDeletedByRetailer || product.status === ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL
    },
    isRemoveEnabled(product) {
      return ((product.status !== ORDER_PRODUCT_SHIPMENT_STATUS.CANCELLED.LABEL) && ((this.isBrand && this.order.status === ORDER_STATUS.ACCEPTED) || (this.isRetailer && product.isEdited && this.order.status === ORDER_STATUS.PENDING_RETAILER)))
    },
    isShowStatusTag(productStatus) {
      const orderProductStatuses = Object.values(ORDER_PRODUCT_SHIPMENT_STATUS).map(
        _ => _.LABEL,
      )
      const shipmentStatuses = Object.values(SHIPMENT_STATUS)
      return orderProductStatuses.includes(productStatus) || shipmentStatuses.includes(productStatus)
    },
  },
  emits: ['update-total', 'update-total-units', 'remove-product', 'ship-now'],
  setup(props, {
    emit,
  }) {
    onMounted(() => {
      window.addEventListener('scroll', onScrollLoader)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', onScrollLoader)
    })

    const variantLoaded = ref(false)
    const isLoaded = ref(false)
    const isSelectedProduct = ref(false)
    const products = ref([])
    const productsSlice = ref([])
    const colorHeading = 'color'
    const headings = ref([{
      key: colorHeading,
      label: 'Color',
    }])

    let [productsSliceX, productsSliceY, pageNumber] = [0, 0, 1]
    productsSlice.value = []

    const onScrollLoader = () => {
      if (window.scrollY > (document.body.scrollHeight - 2000) && products.value.length > productsSliceX) {
        pageNumber += 1
        productsSliceY = products.value.length < (pageNumber * PRODUCTS_LIMIT) ? products.value.length : pageNumber * PRODUCTS_LIMIT
        products.value.slice(productsSliceX, productsSliceY).forEach(p => productsSlice.value.push(p))
        productsSliceX = productsSliceY
      }
    }

    const { parseProductImage } = useEcommerceUi()

    const setOrderProducts = () => {
      productsSlice.value = []
    // receive products from order details
    if (props.orderProducts && props.orderProducts.length) {
        products.value = props.orderProducts
        // Pagination done here
        productsSliceY = products.value.length < PRODUCTS_LIMIT ? products.value.length : PRODUCTS_LIMIT
        products.value.slice(0, productsSliceY).forEach(p => productsSlice.value.push(p))
        productsSliceX = productsSliceY
        isLoaded.value = true
    }
    }

    // Setting order products
    setOrderProducts()

    // update product variant => recalculate total
    const updateProductVariants = (variants, product) => {
      product.variants = variants
      const items = parseProductItems(product)
      product.items = items
      updateTotalPriceAndUnits()
      variantLoaded.value = true
    }

    const updateTotalPriceAndUnits = () => {
      const role = store.getters.entityType
      getProductAndOrderTotals(role, props.order)
    }

    // On select product for shipment
    // Need to checck
    const onSelect = (isSelected, product, index) => {
      if (isSelected) {
        if (props.isShipment) {
          updateTotalPriceAndUnits()
        }
        product.status = ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
      } else {
        if (props.isShipment) {
          updateTotalPriceAndUnits()
        }
        product.status = ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL
      }
      Vue.set(products.value, index, product)
      const isSelectedAtleastOne = products.value.find(
        product => product.status
          === ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL,
      )
      isSelectedProduct.value = !!isSelectedAtleastOne
    }

    // On select all ready to ship products
    const onSelectReadyToShipProducts = isSelected => {
      for (let i = 0; i < products.value.length; i++) {
        if (getStatusTextAndColorCode(products.value[i].status).isEnabled) {
          if (isSelected) {
            products.value[i].status = ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
          } else {
            products.value[i].status = ORDER_PRODUCT_SHIPMENT_STATUS.READY_TO_SHIP.LABEL
          }
          Vue.set(products.value, i, products.value[i])
        }
      }
      isSelectedProduct.value = isSelected
    }

    // On click ship products
    const shipProducts = () => {
      const selectedProducts = []
      const orderData = props.order
      products.value.forEach(product => {
        if (
          product.status
            === ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
          && getStatusTextAndColorCode(product.status).isEnabled
        ) {
          selectedProducts.push(product)
        }
      })
      orderData.isDataForCreateShipment = true
      orderData.products = selectedProducts
      emit('ship-now', orderData)
    }

    // Checking is selected all
    const isSelectedAll = () => {
      if (isSelectedProduct.value) {
        const selectedProducts = products.value.filter(
          product => product.status
            === ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL,
        )
        return props.order.readyToShipProductsCount === selectedProducts.length
      }
      return false
    }

    return {
      products,
      productsSlice,
      isLoaded,
      isSelectedProduct,
      variantLoaded,
      onSelect,
      onSelectReadyToShipProducts,
      isSelectedAll,
      shipProducts,
      formattedDate,

      // UI
      parseProductImage,
      updateProductVariants,

      // Filter
      formatNumber,
      formatCurrency,
      headings,
      colorHeading,
      updateTotalPriceAndUnits,
      setOrderProducts,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-cart-products.scss';
</style>
