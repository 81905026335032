<template>
  <div class="d-flex">
    <b-button
      class="d-flex align-items-center"
      variant="secondary-primary"
      @click="$bvModal.show('download_modal')"
    >
      <img :src="DownloadCloudIcon">
      <span class="ml-10-px">Download Shipment</span>
    </b-button>
    <b-dropdown
      v-if="isBrand"
      variant="secondary-black"
      text="Invoices"
      class="ml-1 split-action-dropdown"
      menu-class="shipment-dropdown-menu scroll-bar"
      toggle-class="shipment-toggle"
    >
      <template>
        <b-dropdown-item
          v-if="invoiceUrl"
          class="invoice-name"
        >
          <span
            @click="download(invoiceUrl)"
          >Download
            {{ getFileName(FILE_TYPES.INVOICE_BY_BRAND, invoiceUrl) }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="onClickUploadInvoice"
        >{{ isUpdateInvoiceEnabled ? 'Update' : 'Upload' }} Invoice</b-dropdown-item>
      </template>
    </b-dropdown>
    <b-modal
      id="invoice-modal"
      modal-class="custom-modal-variant custom-model"
      title="Upload invoice"
      centered
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
      hide-footer
      @show="clearForm"
    >
      <template #modal-title>
        <div class="elements-center">
          <span class="modal-rounded-icon blue-border">
            <upload-icon />
          </span>
          <span> Upload invoice </span>
        </div>
      </template>
      <div class="invoice-upload-form">
        <validation-observer ref="validationObserver">
          <div
            @dragover.prevent
            @drop.prevent
          >
            <validation-provider
              #default="{ errors }"
              name="Invoice File"
              vid="Invoice file"
              rules="required"
            >
              <b-form-group label-for="uploadInvoice">
                <template #label>
                  <span>Upload Invoice
                    <span class="text-danger font-small-4">*</span></span>
                  <div
                    v-if="!invoiceFile"
                    class="fileDownload upload-invoice"
                    @drop="onDropInvoiceFile"
                  >
                    <upload-icon />
                    <h4 class="font-weight-bolder">
                      <b>Drag & Drop</b>
                    </h4>
                    <h6>or click to select files from your system</h6>
                  </div>
                </template>
                <div
                  v-if="invoiceFile"
                  class="file-uploaded mt-50"
                  @drop="onDropInvoiceFile"
                >
                  <div class="elements-center">
                    <b-img
                      :src="pdfIcon"
                      class="mx-1"
                      alt="ErrorIcon"
                    />
                    <div>
                      <div>Name: {{ invoiceFile.name }}</div>
                      <div>
                        Size:
                        {{ (formatNumber(invoiceFile.size / 1024), 2) }} KB
                      </div>
                    </div>
                  </div>
                  <div @click="invoiceFile = null">
                    <delete-icon />
                  </div>
                </div>
                <b-form-file
                  v-if="!invoiceFile"
                  id="uploadInvoice"
                  v-model="invoiceFile"
                  name="uploadInvoice"
                  :hidden="true"
                  plain
                />
                <span
                  v-if="errors.length"
                  class="text-danger font-small-4"
                >
                  {{ errors[0] }}
                </span>
              </b-form-group>
            </validation-provider>
          </div>
        </validation-observer>
        <div>
          <div class="d-flex">
            <div class="w-75 mr-1">
              <validation-provider
                #default="{ errors }"
                vid="invoice-amount"
                name="Invoice Amount"
                rules="required"
              >
                <b-form-group label-for="invoice-amount">
                  <template #label>
                    <span>Invoice Amount
                      <span class="text-danger font-small-4">*</span></span>
                  </template>
                  <b-form-input
                    id="invoice-amount"
                    v-model="invoiceAmount"
                    name="invoice-amount"
                    type="number"
                    placeholder="Type in the invoice amount"
                  />
                  <span
                    v-if="errors.length"
                    class="text-danger font-small-4"
                  >
                    {{ errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="w-25">
              <b-form-group label-for="currency">
                <template #label>
                  <span>Currency
                    <span class="text-danger font-small-4">*</span></span>
                </template>
                <b-dropdown
                  class="w-100 currency-dropdown"
                  :text="currency"
                  variant="outline-none"
                  no-caret
                  toggle-class="border-link-water"
                >
                  <template #button-content>
                    {{ currency || 'Select' }}
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="16"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="currencyValue of Object.values(CURRENCY_CONVERSIONS).map(currency => currency.LABEL)"
                    :key="currencyValue"
                    :active="currency === currencyValue"
                    @click="currency = currencyValue"
                  >
                    {{ currencyValue }}
                  </b-dropdown-item>
                </b-dropdown>
              </b-form-group>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-50 mr-1">
              <validation-provider
                #default="{ errors }"
                name="Invoice Quantity"
                vid="Quantity"
                rules="required"
              >
                <b-form-group label-for="invoice-quantity">
                  <template #label>
                    <span>Invoice Quantity
                      <span class="text-danger font-small-4">*</span></span>
                  </template>
                  <b-form-input
                    id="invoice-quantity"
                    v-model="invoiceQuantity"
                    name="invoice-quantity"
                    type="number"
                    placeholder="Type in the invoice quantity"
                  />
                  <span
                    v-if="errors.length"
                    class="text-danger font-small-4"
                  >
                    {{ errors[0] }}
                  </span>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <div class="d-flex">
            <div class="w-100">
              <b-form-group label-for="invoice-amount-in-usd">
                <template #label>
                  <span>Invoice Amount in USD
                    <span class="text-danger font-small-4">*</span></span>
                </template>
                <b-form-input
                  id="invoice-amount-in-usd"
                  :value="getInvoiceAmountInUSDwithSymbol"
                  name="invoice-amount-in-usd"
                  disabled
                  placeholder="Invoice amount in USD"
                />
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-inline-block cursor-pointer mb-50"
        @click="isVisible = !isVisible"
      >
        <span class="color-dark-blue font-weight-bold">
          <collapse-icon
            :class="isVisible && 'down'"
            class="collapse-svg"
          />
          Expected Amount:</span>
        <span class="color-blue">
          {{ formatNumber(shipment.totalPriceWithVatForBrand || shipment.totalPriceForBrand, 2) }} USD
        </span>
      </div>
      <b-collapse
        id="expected"
        :visible="isVisible"
        class="amount-collapse-block"
      >
        <div class="d-flex justify-content-between">
          <span class="color-black">Total quantity:</span>
          <span class="color-actions-text-grey">{{ shipment.totalQuantity }}</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="color-black">Total cost:</span>
          <span class="color-actions-text-grey">{{
            formatNumber(shipment.totalPriceForBrand, 2)
          }}USD</span>
        </div>
        <div class="d-flex justify-content-between">
          <span class="color-black">Amount with VAT:</span>
          <span class="color-actions-text-grey">{{
            formatNumber(shipment.totalPriceWithVatForBrand || shipment.totalPriceForBrand, 2)
          }}USD</span>
        </div>
      </b-collapse>
      <div class="elements-center p-1">
        <b-button
          variant="outline-secondary-info mr-1"
          @click="$bvModal.hide('invoice-modal')"
        >
          Cancel
        </b-button>
        <b-button
          variant="secondary-info"
          :disabled="isUploading || !isValid"
          @click="uploadInvoice"
        >
          {{ isDispute ? isUploading ? 'Disputing' : 'Dispute' : isUploading ? 'Uploading' : 'Upload' }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BFormFile,
  BImg,
  BCollapse,
  BButton,
} from 'bootstrap-vue'
import {
  formatNumber,
  getFileName,
  download,
  formatObject,
} from '@/@core/utils/utils'
import store from '@/store'
import {
  UPDATE_SHIPMENT,
} from '@/store/modules/shipment.module'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import UploadIcon from '@/@core/assets/svg-components/UploadIcon.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'
import DeleteIcon from '@/@core/assets/svg-components/DeleteIcon.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import constants, { FILE_TYPES } from '@/constants'
import CollapseIcon from '@/@core/assets/svg-components/CollapseIcon.vue'
import analytics from '@/@core/utils/analytics'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import DownloadCloudIcon from '@/assets/images/icons/download-cloud-icon.svg'

const { round } = utils

const {
 KP_DATE_FORMAT,
  CURRENCY_CONVERSIONS,
  SHIPMENT_STATUS,
} = c

export default {
  name: 'ShipmentAction',
  components: {
    BDropdown,
    BDropdownItem,
    BModal,
    UploadIcon,
    ValidationObserver,
    ValidationProvider,
    BFormFile,
    BFormGroup,
    BFormInput,
    BImg,
    BCollapse,
    DeleteIcon,
    FeatherIcon,
    BButton,
    CollapseIcon,
},
  directives: {
    'b-modal': VBModal,
  },
  props: {
    shipment: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      invoiceFile: null,
      invoiceAmount: null,
      currency: CURRENCY_CONVERSIONS.USD.LABEL,
      invoiceQuantity: null,
      invoiceAmountInUSD: null,
      exchangeRate: null,
      pdfIcon: require('@/assets/images/icons/pdficon.svg'),
      formatNumber,
      KP_DATE_FORMAT,
      isVisible: false,
      invoiceFileLocation: null,
      invoiceUrl: '',
      isUploading: false,
      FILE_TYPES,
      CURRENCY_CONVERSIONS,
      getFileName,
      download,
      shippedDate: null,
      SHIPMENT_STATUS,
      DownloadCloudIcon,
    }
  },
  computed: {
    isValid() {
      return (
        this.invoiceFile
        && this.invoiceAmount > 0
        && this.invoiceQuantity > 0
      )
    },
    isBrand() {
      return store.getters.isBrand
    },
    getInvoiceAmountInUSDwithSymbol() {
      if (this.invoiceAmount <= 0) {
        return null
      }
      return formatNumber(
        this.invoiceAmount * CURRENCY_CONVERSIONS.USD[this.currency],
        2,
      )
    },
    getAmountInUSD() {
      if (this.invoiceAmount <= 0) {
        return 0
      }
      return round(this.invoiceAmount, CURRENCY_CONVERSIONS.USD[this.currency])
    },
    isDispute() {
      const isQuantityMatched = Number(this.invoiceQuantity) === this.shipment.totalQuantity
      const isPriceMatched = Number(this.getAmountInUSD) >= round((this.shipment.totalPriceWithVatForBrand || this.shipment.totalPrice), 0.98) && Number(this.getAmountInUSD) <= round((this.shipment.totalPriceWithVatForBrand || this.shipment.totalPrice), 1.02)
      return this.isValid && !(isQuantityMatched && isPriceMatched)
    },
    isUpdateInvoiceEnabled() {
     return !!this.shipment.totalPriceFromBrand
    },
  },
  emits: ['load-shipment'],
  mounted() {
    if (this.shipment?.invoiceUrl) {
      this.invoiceUrl = this.shipment.invoiceUrl
    }
  },
  methods: {
    onDropInvoiceFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      this.invoiceFile = droppedFile
    },
    async uploadInvoice() {
      this.isUploading = true
      if (this.invoiceFile instanceof File) {
        this.invoiceFileLocation = await store.dispatch(
          UPLOAD_DOCUMENTS,
          { fileType: FILE_TYPES.INVOICE_BY_BRAND, file: this.invoiceFile },
        )
      }
      const payload = {
          invoiceUrl: this.invoiceFileLocation,
          currencyFromBrand: this.currency,
          totalQuantityFromBrand: Number(this.invoiceQuantity),
          totalPriceFromBrand: Number(this.invoiceAmount),
      }

      store.dispatch(UPDATE_SHIPMENT, { shipmentId: this.shipment._id, payload })
      .then(res => {
          apiToastSuccess(res.data.message)
          this.isUploading = false
          this.$bvModal.hide('invoice-modal')
          this.trackUploadShipmentDocuments()
          this.$emit('load-shipment')
        })
        .catch(err => {
          this.isUploading = false
          apiToastError(err)
        })
    },
    clearForm() {
      this.invoiceFile = null
      this.invoiceAmount = null
      this.currency = CURRENCY_CONVERSIONS.USD.LABEL
      this.invoiceQuantity = null
      this.invoiceAmountInUSD = null
      this.exchangeRate = null
      this.invoiceFileLocation = null
      this.isVisible = false
    },
    setEditableFields() {
        this.invoiceFile = {
          name: getFileName(FILE_TYPES.INVOICE_BY_BRAND, this.invoiceUrl || ''),
        }
        this.invoiceFileLocation = this.invoiceUrl || ''
        this.currency = this.shipment.currencyFromBrand
        this.invoiceQuantity = this.shipment.totalQuantityFromBrand
        this.invoiceAmount = this.shipment.totalPriceFromBrand
    },
    onClickUploadInvoice() {
        this.$bvModal.show('invoice-modal')
        if (this.isUpdateInvoiceEnabled) {
            this.setEditableFields()
        }
    },
    trackUploadShipmentDocuments(shippedDate = null) {
      const trackData = {
        noOfProductsInShipment: this.shipment.products?.length || 0,
        collectionName: this.shipment.collectionName,
        logisticsTerms: this.shipment.logisticsTerms,
      }
      const action = shippedDate ? constants.TRACKS.ACTIONS.BRAND_MARKS_SHIPMENT_COLLECTED : constants.TRACKS.ACTIONS.BRAND_UPLOADS_SHIPMENT_DOCUMENTS
      analytics.track(action, formatObject(trackData))
    },
  },
}
</script>
