<template>
  <div>
    <order-detail
      ref="orderDetailPage"
      :is-shipping="isShipping"
      @ship-now="onClickShipNow"
    />
    <div>
      <b-modal
        :id="SHIPMENT_SETTINGS_MODAL_ID"
        size="xl"
        modal-class="modal-primary"
        body-bg-variant="light"
        centered
        title="Shipment Settings"
        hide-footer
        @hide="cancelShipment"
      >
        <order-detail
          v-if="shipmentOrder"
          :shipment-order="shipmentOrder"
          :is-shipping="isShipping"
          @cancel-create-shipment="cancelShipment"
          @create-shipment="confirmShipment"
        />
      </b-modal>
    </div>

    <b-modal
      id="shipment_restrict_modal"
      size="lg"
      modal-class="modal-warning custom-model"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div class="pb-50 border-bottom">
          <span class="custom-modal-heading">Create Shipment Warning </span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide('shipment_restrict_modal')"
          />
        </div>
        <p class="mt-1 text-center product-message">
          {{ createShipmentWarningMessage }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VBModal } from 'bootstrap-vue'
import { apiToastSuccess, apiToastWarning } from '@/@core/utils/toast'
import constants from '@/constants'
import analytics from '@/@core/utils/analytics'
import { formatObject } from '@/@core/utils/utils'
import store from '@/store'
import { CREATE_SHIPMENT } from '@/store/modules/shipment.module'
import OrderDetail from './OrderDetail.vue'

const { ORDER_PRODUCT_SHIPMENT_STATUS } = constants
const SHIPMENT_SETTINGS_MODAL_ID = 'modal_shipment_settings'

export default {
  name: 'Order',
  components: {
    'order-detail': OrderDetail,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      shipmentOrder: null,
      isShipping: false,
      totalOrderProductsCount: 0,
      createShipmentWarningMessage: '',
      SHIPMENT_SETTINGS_MODAL_ID,
    }
  },
  methods: {
    onClickShipNow(ShipmentOrderData, totalOrderProductsCount = 0) {
      this.totalOrderProductsCount = totalOrderProductsCount
      this.shipmentOrder = ShipmentOrderData
      this.$bvModal.show(SHIPMENT_SETTINGS_MODAL_ID)
    },
    confirmShipment() {
      this.isShipping = true
      const productIds = []
      this.shipmentOrder.products.forEach(product => {
        if (
          product.status
          === ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
        ) {
          productIds.push(product.productId)
        }
      })
      const payload = {
        orderId: this.shipmentOrder._id,
        productIds,
      }
      store.dispatch(CREATE_SHIPMENT, payload)
        .then(res => {
          this.isShipping = false
          this.shipmentOrder.products.forEach(product => {
            if (
              product.status
              === ORDER_PRODUCT_SHIPMENT_STATUS.SELECTED_BY_USER.LABEL
            ) {
              product.status = ORDER_PRODUCT_SHIPMENT_STATUS.SHIPPED.LABEL
            }
          })
          apiToastSuccess(res.data.message || 'Products added to shipment')
          analytics.track(constants.TRACKS.ACTIONS.RETAILER_CREATES_SHIPMENT, formatObject({ noOfProductsInOrder: this.totalOrderProductsCount, noOfProductsInShipment: payload.productIds?.length }))
          this.cancelShipment()
          this.$refs.orderDetailPage.loadOrder()
        })
        .catch(err => {
          this.isShipping = false
          if (err.response.status === 422 && err.response.data?.data?.creditLimitExceeded) {
            this.createShipmentWarningMessage = err.response.data?.data?.message
            this.$bvModal.show('shipment_restrict_modal')
          } else {
            apiToastWarning(err)
          }
        })
    },
    cancelShipment() {
      this.shipmentOrder = null
      this.$bvModal.hide(SHIPMENT_SETTINGS_MODAL_ID)
    },
  },
}
</script>
