<template>
  <b-card no-body>
    <!-- timeline -->
    <b-card-body>
      <div class="field-heading color-dark-blue">
        {{ order.shipmentId ? 'Shipment' : 'Order' }} Status
      </div>
      <app-timeline>
        <app-timeline-item
          v-for="log in order.logs.slice()"
          :key="log._id"
          :title="
            isRetailer
              ? log.textForRetailers || log.status
              : log.textForBrands || log.status
          "
          :time="showRelativeTime(log.timestamp)"
          :icon="parseOrderIcon(log.status)"
          :variant="parseOrderVariant(log.status)"
        />
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody } from 'bootstrap-vue'
import AppTimeline from '@/@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@/@core/components/app-timeline/AppTimelineItem.vue'
import { showRelativeTime } from '@/@core/utils/text'
import { parseOrderIcon, parseOrderVariant } from './order-utils'
import UserRoleMixin from '../UserRoleMixin.vue'

export default {
  name: 'OrderLogs',
  components: {
    BCard,
    BCardBody,
    AppTimeline,
    AppTimelineItem,
  },
  mixins: [UserRoleMixin],
  props: {
    order: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      showRelativeTime,
      parseOrderIcon,
      parseOrderVariant,
    }
  },
}
</script>
